export const contact={
	'contact.title':'Contact',

	'contact.section1_1':`
		
	`,
	'contact.section1_2':`
		
	`,
}

