export interface Customer {
  timestamp: number
  name: string
}

export interface Cell {
  value: string
	readOnly?: boolean,
}

export interface WorkTime {
	start: Date
	stop: Date
}

export interface InvoiceRow {
  timestamp: number
	item: string
	quantity: number
	price:number
	alv:number
}

export interface ProductHistory {
  timestamp: number
	item: string
	quantity: number
}


export interface Invoice {
  timestamp: number
  customer?: string
	items: InvoiceRow[]
}

export interface Message {
  user: string
  message: string
  timestamp: number
}

export interface ChatState {
  messages: Message[]
	customers: Customer[]
	productstable: Cell[][]
	worktimes: WorkTime[]
	invoicerows: InvoiceRow[]
	invoice: Invoice
	productshistory: ProductHistory[]
}

export const SEND_MESSAGE = 'SEND_MESSAGE'
export const UPDATE_MESSAGE = 'UPDATE_MESSAGE'
export const DELETE_MESSAGE = 'DELETE_MESSAGE'
export const DB_INITIALIZE = 'DB_INITIALIZE'

export const DB_UPDATE = 'DB_UPDATE'

interface DbUpdateAction {
	type: typeof DB_UPDATE
	payload: ChatState
}

interface SendMessageAction {
  type: typeof SEND_MESSAGE
  payload: Message
}

interface UpdateMessageAction {
  type: typeof UPDATE_MESSAGE
  payload: Message
}


interface DeleteMessageAction {
  type: typeof DELETE_MESSAGE
  meta: {
    timestamp: number
  }
}

interface DbInitializeAction {
  type: typeof DB_INITIALIZE
	state: ChatState
}


export type ChatActionTypes = SendMessageAction | DeleteMessageAction | UpdateMessageAction | DbInitializeAction | DbUpdateAction
