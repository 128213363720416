import React from 'react'

interface IProps {
}

interface IState {
	height: number;
	width: number;
	anim: number;
}

class NomgaCanvas extends React.Component<IProps, IState> {
	state: IState;
	canvas?: HTMLCanvasElement;
	canvash?: HTMLDivElement;
	objectList: any;
	addMore: boolean;
	letterCount: any;
	animation: any;
	requestAnimationFrame: any;
	autoStart: any;

	constructor(props: IProps) {
		super(props)
		this.state={
			height:window.innerHeight-400, //300,
			width:window.innerWidth,
			anim:1,
		};

		this.canvas = undefined;
		this.context = null; 
		
		this.objectList=[];
		this.addMore=true
		this.letterCount=0

		this.autoAdd=this.autoAdd.bind(this)
		this.autoAdd2=this.autoAdd2.bind(this)

		this.drawCircle=this.drawCircle.bind(this)
		this.setResize=this.setResize.bind(this)
		window.addEventListener('resize',this.setResize)

		this.animation={
			1:this.autoAdd,
			2:this.autoAdd2,
		}
		this.animmode=this.animmode.bind(this)

		setTimeout(this.animmode,5000)

	}

	animmode() {
		this.setState({anim:this.state.anim===1?2:1})
		setTimeout(this.animmode,5000)

	}

	setResize() {
		if(this.canvash) {
  	this.setState({
			width:window.innerWidth,
			height:window.innerHeight,
		})
		this.context={}
		}
	}


	componentDidMount() {

		this.requestAnimationFrame = window.requestAnimationFrame || 
                            (window as any).mozRequestAnimationFrame || 
                            window.webkitRequestAnimationFrame || 
                            (window as any).msRequestAnimationFrame;

		this.drawCircle()

		this.autoStart=setTimeout(this.animation[this.state.anim],1000)
	
	}



	rx(x:number,y:number,a:number) {
		return Math.cos(a)*x+Math.sin(a)*y
	}

	ry(x:number,y:number,a:number) {
		return -Math.sin(a)*x+Math.cos(a)*y
	}

	drawChar(ctx:any,x:number,y:number,a:number,z:number,lc:any) { 

	
	ctx.beginPath();
	ctx.lineWidth=4;

//  ctx.strokeStyle = 'rgba(255,255,255,'+(1-(600-x)/600)+')'
  ctx.strokeStyle = 'rgba(0,0,0,'+(1-(600-x)/600)+')'

	switch(lc) {
		case 0:
			ctx.moveTo(x+this.rx(-50*z, 50*z, a),y+this.ry(-50*z, 50*z, a));
			ctx.lineTo(x+this.rx(-50*z,-50*z, a),y+this.ry(-50*z,-50*z, a));
			ctx.lineTo(x+this.rx( 50*z, 50*z, a),y+this.ry( 50*z, 50*z, a));
			ctx.lineTo(x+this.rx( 50*z,-50*z, a),y+this.ry( 50*z,-50*z, a));
			ctx.stroke()

	    ctx.strokeStyle = "#d1a827";
			ctx.lineWidth=6;
			ctx.beginPath();

			const a2=a*0.5
			ctx.moveTo(x+this.rx(-80*z, 80*z, a2),y+this.ry(-80*z, 80*z, a2));
			ctx.lineTo(x+this.rx(-80*z,-80*z, a2),y+this.ry(-80*z,-80*z, a2));
			ctx.lineTo(x+this.rx( 80*z,-80*z, a2),y+this.ry( 80*z,-80*z, a2));
			ctx.lineTo(x+this.rx( 80*z, 80*z, a2),y+this.ry( 80*z, 80*z, a2));
			ctx.lineTo(x+this.rx(-80*z, 80*z, a2),y+this.ry(-80*z, 80*z, a2));


			break
		
		case 1:
			ctx.moveTo(x+this.rx(-50*z, 50*z, a),y+this.ry(-50*z, 50*z, a));
			ctx.lineTo(x+this.rx(-50*z,-50*z, a),y+this.ry(-50*z,-50*z, a));
			ctx.lineTo(x+this.rx( 50*z,-50*z, a),y+this.ry( 50*z,-50*z, a));
			ctx.lineTo(x+this.rx( 50*z, 50*z, a),y+this.ry( 50*z, 50*z, a));
			ctx.lineTo(x+this.rx(-50*z, 50*z, a),y+this.ry(-50*z, 50*z, a));

			break

		case 2:
			ctx.moveTo(x+this.rx(-50*z, 50*z, a),y+this.ry(-50*z, 50*z, a));
			ctx.lineTo(x+this.rx(-50*z,-50*z, a),y+this.ry(-50*z,-50*z, a));
			ctx.lineTo(x+this.rx(  0*z,  0*z, a),y+this.ry(  0*z,  0*z, a));
			ctx.lineTo(x+this.rx( 50*z,-50*z, a),y+this.ry( 50*z,-50*z, a));
			ctx.lineTo(x+this.rx( 50*z, 50*z, a),y+this.ry( 50*z, 50*z, a));

			break

		case 3:
			ctx.moveTo(x+this.rx( 50*z,-50*z, a),y+this.ry( 50*z,-50*z, a));
			ctx.lineTo(x+this.rx(-50*z,-50*z, a),y+this.ry(-50*z,-50*z, a));
			ctx.lineTo(x+this.rx(-50*z, 50*z, a),y+this.ry(-50*z, 50*z, a));
			ctx.lineTo(x+this.rx( 50*z, 50*z, a),y+this.ry( 50*z, 50*z, a));
			ctx.lineTo(x+this.rx( 50*z,  0*z, a),y+this.ry( 50*z,  0*z, a));
			ctx.lineTo(x+this.rx(  0*z,  0*z, a),y+this.ry(  0*z,  0*z, a));


			break

		case 4:
			ctx.moveTo(x+this.rx(-50*z, 50*z, a),y+this.ry(-50*z, 50*z, a));
			ctx.lineTo(x+this.rx(-50*z,-50*z, a),y+this.ry(-50*z,-50*z, a));
			ctx.lineTo(x+this.rx( 50*z,-50*z, a),y+this.ry( 50*z,-50*z, a));
			ctx.lineTo(x+this.rx( 50*z, 50*z, a),y+this.ry( 50*z, 50*z, a));

			ctx.moveTo(x+this.rx(-50*z,  0*z, a),y+this.ry(-50*z,  0*z, a));
			ctx.lineTo(x+this.rx( 50*z,  0*z, a),y+this.ry( 50*z,  0*z, a));

			break
		default:	
			break

	}

	ctx.stroke();
	}

	getMousePos(canvas:any, evt:any) {
    var rect = canvas.getBoundingClientRect();
    return {
      x: evt.clientX - rect.left,
      y: evt.clientY - rect.top
    };
	}

	add2list(m:any) {
		if(this.objectList.length>50) {
			this.objectList.shift();
		}
		this.letterCount++;
		if(this.letterCount>=m) this.letterCount=0;

	}

	autoAdd() {
		this.objectList.push({x:this.state.width,y:20,a:-1,c:0,lc:this.letterCount})

		this.add2list(20)

		this.autoStart=setTimeout(this.animation[this.state.anim],200)
	}

	autoAdd2() {
		this.objectList.push({
			x:Math.random()*this.state.width,
			y:Math.random()*this.state.height,
			a:-1,
			c:0,
			lc:this.letterCount
		})

		this.add2list(5)
		this.autoStart=setTimeout(this.animation[this.state.anim],100)
	}


	drawCircle() {
		if(this.canvas) {
		if(this.context.clearRect) {
	    this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);

			for(var i=0;i<this.objectList.length;i++) {
				this.objectList[i].a+=0.02; //Math.PI/64
				this.objectList[i].x-=5
				this.objectList[i].y+=(this.state.width-this.objectList[i].x)*(3/this.state.width) //0.005
	
				this.objectList[i].c+=5/this.state.width //0.01
	
				this.drawChar(this.context,this.objectList[i].x,this.objectList[i].y,this.objectList[i].a,this.objectList[i].c,this.objectList[i].lc)
			}
		}
		else {
			this.context=this.canvas.getContext('2d')
		}


    this.requestAnimationFrame.call(window,this.drawCircle);
		}
	}


  render() {
		return(<div ref={ref=>this.canvash=ref || undefined}>

			<canvas ref={ref=>this.canvas=ref || undefined} height={this.state.height} width={this.state.width} ></canvas>
		</div>)
  }
}

export default NomgaCanvas;
