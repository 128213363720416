export const software={
	'software.title':'Ohjelmistokehitys',
  'software.title2':'Selainkäyttöiset (www) sovellukset',

	'software.section1_1':`
       Selaimella toimivat sovellukset ovat helposti kaikkien niitä tarvitsevien
       käsillä ja tiedot saatavilla paikasta riippumatta.
	`,
	
	'software.section1_2':`
       Suunnittelemme ja toteutamme sovellukset yhdessä teidän
       kanssanne, teidän tarpeisiinne.
	`,

	'software.section1_3':`
       Toiminnanohjausjärjestelmä, ERP - Enterprise Resource Planning
	`,
}

