export const software={
	'software.title':'Software Development',
	'software.title2':'Browser (www) applications',

	'software.section1_1':`
			Browser-enabled applications are easily accessible to anyone who needs them
      on hand and information available regardless of location.
	`,
	
	'software.section1_2':`
			We design and implement applications together with you
      with you, for your needs.
	`,
	
	'software.section1_3':`
      ERP - Enterprise Resource Planning
	`,

}

