export const warehouse={
	'warehouse.title':'Warehouse',
	'warehouse.inventory':'Inventory',

	'warehouse.section1_1':`
			Know what's in stock.
	`,

	'warehouse.section1_2':`
		With inventory management, you make sure there is stuff in stock when you need it.
	`,

	'warehouse.section2':`
		From inventory accounting, you can see who has taken what and used what. Nothing goes uninvoiced.
	`,

	'warehouse.btn_delete_row':'Delete row',
	'warehouse.btn_add_row':'Add row',

}
