export const timecard={
	'timecard.title':'Time tracking',
	'timecard.timecard':'Timecard',
	'timecard.register':'Working hours register',

	'timecard.btn_start':'Start',
	'timecard.btn_stop':'Stop',

	'timecard.section1_1':`
			With a mobile clock card, you get accurate and easy-to-maintain accounting.
	`,
	'timecard.section1_2':`
			In connection with the use of the time card, it is also possible to collect other relevant information and obtain accurate information on, for example, travel times, profitability of projects, additional work, materials used.
	`,
	
	'timecard.section2':`
			Well-made markings are already documentation in themselves. Added the ability to take pictures and fill out reports so the job is ready to be billed right away.
	`,
	
	'timecard.section3_1':`
			Monitoring of working hours is also mandatory in Finland for monthly employees, salaried employees and experts.
	`,
	'timecard.section3_2':`
			Because the law requires you to keep time and attendance records, it's a good idea to take advantage of it in business at the same time.
	`,
	'timecard.section3_3':`
			Working hours can be used as a basis for payroll and as an aid to invoicing. They can be targeted to projects and other information can be attached to them. Immediate documentation provides the company with real-time information about the state of the company and helps develop more efficient ways of operating
	`,
	
	'timecard.section4':`
			When working hours are collected regularly from everyone, it is easy to further process and analyze the data
	`,

	'timecard.terminal':'Worktime terminal',

	'timecard.section5_1':`
			With the working time terminal, stamping is quick and easy.
	`,

	'timecard.section5_2':`
			The stamping device is very simple in practice. Computer, NFC reader and internet connection.
	`,
	'timecard.section5_3':`
      Below is an example of a configuration with an additional screen to inform the user of the function
      success.
	`,

	'timecard.section6_1':`
			(example prices VAT24% purchased from various stores)
	`,

	'timecard.section6_2':`
			In addition, a memory card, case, power supply and a few wires are required
	`,

	'timecard.section7':`
			Ready-made devices can be found in the market for every need with many different features.
	`,
	'timecard.ul':'Additional features may include:',
  'timecard.li1':'battery backup',
  'timecard.li2':'mobile connection',
  'timecard.li3':'relay control to open the locks',
  'timecard.li4':'weather resistance',
  'timecard.li5':'fingerprint reader',
  'timecard.li6':'keypad for PIN code',
  'timecard.li7':'camera',


	'timecard.section8_1':`
		The stamping device also requires software to which entries are sent.
	`,
	'timecard.section8_2':`
    Nomga Calendar is a simple solution for collecting data from one person,
    but it makes it possible to build a company-wide system.
	`,
	'timecard.section8_3':`
    The key is to facilitate individual human methods for producing quality information.
	`,



}


