import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

import * as serviceWorker from './serviceWorker';

import { Provider } from 'react-redux';

import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import configureStore from "./store";

import { dbInitialize } from './store/chat/actions';

import { AppLanguage } from './i18n/const';

const store = configureStore();
store.dispatch(dbInitialize());

const language=navigator.languages.reduce((resp,ob)=>{
	const lang=ob.split(/-|_/)[0] as AppLanguage;
	if(resp==="" && Object.values(AppLanguage).includes(lang)) {
		resp=lang;
	}
	return resp
},"") || "fi"

ReactDOM.render(<Provider store={store}>	
		{/*<React.StrictMode>*/}
			<App defaultLanguage={language as AppLanguage || AppLanguage.Suomi}/>
		{/*</React.StrictMode>*/}
	</Provider>,
	document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
