import React, { useState } from 'react';

import Logo from './Logo';

import { useLocation } from 'react-router-dom';

import { LocalizedLink } from './i18n/LocalizedLink';
import { Link } from 'react-router-dom';

import { AppRoute, AppLanguage } from './i18n/const';

import { appStrings } from './i18n';

import { useIntl } from 'react-intl';

import { FormattedMessage } from 'react-intl';

const classes=require('./MenuBar.module.scss');

interface IProps {
}

const MenuBar: React.FC<IProps> = (props: IProps) => {
	const { locale, messages } = useIntl();
	const { pathname } = useLocation();

	const [ showMenu, toggleMenu ] = useState(false);

	const getMatchingRoute = (language:string) => {
    const [, route] = pathname.split(locale);
    const routeKey = Object.keys(messages).find(key => messages[key] === route);

		if(routeKey) {
			const matchingRoute = (appStrings as any)[language][routeKey];
			return `/${language}`+ matchingRoute;
		}
		return `/${language}`;
  }

	const toggle = () => { toggleMenu(!showMenu) }

	const pullDown=[classes.pulldown];
	if(showMenu) { pullDown.push(classes.show) }

	return(<div className={classes.menubar}>
		<div>
			<LocalizedLink to={AppRoute.Home} ><Logo/></LocalizedLink>
		</div>
		<div className={classes.menuRight}>
			{/*
			<div className={classes.lang}>
			{Object.keys(AppLanguage).map( (lang) => {
				return (
					<Link
						className={classes.lang}
						key={lang}
						to={getMatchingRoute((AppLanguage as any)[lang])}
					>{lang}</Link>
				)})}
			</div>
			*/}
				{' '}<div className={classes.btn} onClick={toggle}><i className="fas fa-bars fa-2x"/></div>
				<div className={pullDown.join(" ")}>
					{Object.keys(AppLanguage).map( (lang) => {
						return (
							<Link
								className={classes.lang}
								key={lang}
								to={getMatchingRoute((AppLanguage as any)[lang])}
							>{lang}</Link>
						)
					})}
{/*
					<div>About</div>
					<LocalizedLink to={AppRoute.Contact}><FormattedMessage id="contact.title"/></LocalizedLink>
*/}					
				</div>
		</div>

  </div>);
}

export default MenuBar;
