import { LanguageStrings } from './base-strings';

import { warehouse } from './en-warehouse';
import { software } from './en-software';
import { ncalendar } from './en-ncalendar';
import { contact } from './en-contact';
import { customers } from './en-customers';
import { timecard } from './en-timecard';
import { invoice } from './en-invoice';

export const en: LanguageStrings = {
	'routes.home':'/',
	'routes.warehouse':'/warehouse',
	'routes.customers':'/customers',
	'routes.invoice':'/invoice',
	'routes.worktime':'/worktime',
	'routes.timecard':'/timecard',
	'routes.contact':'/contact',

	'logo.software':'SOFTWARE',

	'privacy':'Privacy',
	'cookie.text':'We use cookies to provide the best possible user experience for our site visitors.',
	'cookie.btn':'Accept',

	...warehouse,
	...software,
	...ncalendar,
	...contact,
	...customers,
	...timecard,
	...invoice,
};
