import { warehouse } from './fi-warehouse';
import { software } from './fi-software';
import { ncalendar } from './fi-ncalendar';
import { contact } from './fi-contact';
import { customers } from './fi-customers';
import { timecard } from './fi-timecard';
import { invoice } from './fi-invoice';

const baseStrings = {
	'routes.home':'/',
	'routes.warehouse':'/varastonhallinta',
	'routes.customers':'/asiakkaat',
	'routes.invoice':'/laskutus',
	'routes.worktime':'/tyoajanseuranta',
	'routes.timecard':'/kellokortti',
	'routes.contact':'/yhteystiedot',

	'logo.software':'OHJELMISTOT',

	'privacy':'Tietosuojaseloste',
	'cookie.text':'Käytämme evästeitä, jotta voisimme tarjota parhaan mahdollisen käyttäjäkokemuksen sivuston kävijälle.',
	'cookie.btn':'Hyväksy',

	...warehouse,
	...software,
	...ncalendar,
	...contact,
	...customers,
	...timecard,
	...invoice,
}

export type LanguageStrings = typeof baseStrings;

export const fi = baseStrings;
