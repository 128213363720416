export const customers={
	'customers.title':'Asiakkaat',

	'customers.section1_1':`
			Asiakkuudenhallinta, CRM - Customer Relation Management
	`,
	'customers.section1_2':`
			Asiakastietojen käsittely on yrityksen keskeisiä asioita. Vähintäänkin laskutustiedot on oltava kunnossa laskutuksen onnistumiseksi.
			Asiakasrekisteri voidaan rakentaa yrityskohtaisesti vastaamaan yrityksen tarpeita.
	`,
	'customers.section1_3':`
			Esimerkkinä yksinkertainen lista, johon voi poimia tiedot YTJ:n tietokannasta hakemalla.
	`,

	'customers.customerregister':'Asiakasrekisteri',

	'customers.opendatatitle':'Avoin data',
	
	'customers.section2_1':`
			PRH:n avointa rajapintaa hyödyntämällä saadaan asiakasrekisteriin tiedot haettua automaattisesti ja vältytään turhilta kirjoitusvirheiltä.
	`,
	'customers.section2_2':`
			Kokeile hakea yritystä ja lisätä se rekisteriin
	`,
	'customers.section2_3':`
			Huom. PRH:n haku etsii yritystä nimen alusta
	`,
		
	'customers.section3':`
			Avointa dataa voidaan hyödyntää moniin muihinkin eri tarkoituksiin, esim. kohteiden lämpötilan seurantaan
	`,

	'customers.placeholder_search':'Etsi yritystä...',
	'customers.btn_search':'Hae YTJ-tiedot PRH avoin data',

}

