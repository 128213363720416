export const invoice={
	'invoice.title':'Invoicing',

	'invoice.section1_1':`
			Invoicing is made easier by combining customer data, hourly tracking and inventory data.
	`,
	'invoice.section1_2':`
			By adding the item selection to the clock card, the supply of supplies, etc., the invoice is obtained pre-filled.
      The person responsible for invoicing only has to check that the information is correct and send it
      fall forward.
	`,
	'invoice.section1_3':`
			Forwarding invoices depends on other systems in place to get the most out of it.
      It can be done, either by printing on paper, by email, by exporting the file, or by using interfaces.
	`,

	'invoice.customer':'Customer',
	'invoice.add_product':'Add product',
	'invoice.add_hours':'Add hours',

	'invoice.product':'Product',
	'invoice.quantity':'Quantity',
	'invoice.price':'Price',
	'invoice.total':'Total',

	'invoice.total_notax':'Tax free total',
	'invoice.total_tax':'Tax (VAT 24%) total',
	'invoice.total_all':'Invoice total',


}

