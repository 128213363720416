export const customers={
	'customers.title':'Customers',

	'customers.section1_1':`
			CRM - Customer Relation Management
	`,
	'customers.section1_2':`
			The processing of customer data is a key part of the company. At a minimum, the billing information must be in order for billing to be successful.
      The customer register can be built company-specifically to meet the company's needs.
	`,
	'customers.section1_3':`
			As an example, a simple list to which data can be extracted from the YTJ database by searching.
	`,
	
	'customers.customerregister':'Customer Register',

	'customers.opendatatitle':'Open data',

	'customers.section2_1':`
			By utilizing the PRH's open interface, information can be retrieved to the customer register automatically and unnecessary typographical errors are avoided.
	`,
	'customers.section2_2':`
			Try searching for a company and adding it to the register.
	`,
	'customers.section2_3':`
			Note. The PRH's search searches for a company at the beginning of the name
	`,
		
	'customers.section3':`
			Open data can be utilized for many other different purposes, such as monitoring the temperature of objects
	`,

	'customers.placeholder_search':'Search for company...',
	'customers.btn_search':'Search YTJ-data PRH open data',

}

