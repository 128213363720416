import React from 'react';
import { useIntl } from 'react-intl';

import CookieConsent from "react-cookie-consent";

import { FormattedMessage } from 'react-intl';

interface IProps {
}

export const Cookie: React.FC<IProps> = () => {
	const { formatMessage } = useIntl();

	const btn = formatMessage({id: 'cookie.btn'});

	return (
     <CookieConsent
        cookieName="user-has-accepted-cookies"
        buttonText={btn}
			>
				<FormattedMessage id="cookie.text"/><br/>
				<a target="_blank" rel="noopener noreferrer" href="https://www.nomga.fi/privacy.html"><FormattedMessage id="privacy"/></a>
			</CookieConsent>
  );

};
