import React from 'react';

import { connect } from "react-redux";
import { AppState } from "./store";

import { FormattedMessage } from 'react-intl';

import { Row, Col } from 'reactstrap';

const classes=require('./App.module.scss');

interface IProps {
}


const ContactPage: React.FC<IProps> = (props: IProps) => {

	return (<div>
			<Row className={classes.gold}>
				<Col>
					{/*<h1><FormattedMessage id="contact.title"/></h1>*/}
					<p><FormattedMessage id="contact.section1_1"/></p>
					<p><FormattedMessage id="contact.section1_2"/></p>
			
				</Col>
				<Col xs={12} md={6} className={classes.contact}>
					<img className={classes.face} src="/static/images/seppo.png" alt="Seppo Tillanen"/>
					<h5><a href="mailto:seppo.tillanen@nomga.fi"><i className="fas fa-envelope"/> seppo.tillanen@nomga.fi</a></h5>
					<h5><a href="tel:+358504426999"><i className="fas fa-phone"/> +358 50 442 6999</a></h5>
					<h5>
						<a target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/in/seppotillanen/"><i className="fab fa-linkedin"/></a>{' '}
					</h5>
				</Col>
			</Row>


	</div>);
}

const mapStateToProps = (state: AppState) => ({
});

export default connect(
  mapStateToProps,
  {}
)(ContactPage);

