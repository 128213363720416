
export enum AppLanguage {
		Suomi = 'fi',
		English = 'en',
}

export enum AppRoute {
	Home = 'routes.home',
	Warehouse = 'routes.warehouse',
	Customers = 'routes.customers',
	Invoice = 'routes.invoice',
	Worktime = 'routes.worktime',
	Timecard = 'routes.timecard',
	Contact = 'routes.contact',
}


