export const ncalendar={
	'ncalendar.title':'Nomga Calendar',

	'ncalendar.section1':`
		 Working time tracking can be done in many ways. Calendar entry is simple and easy to understand.
	`,

	'ncalendar.googletitle':'Google Calendar',

	'ncalendar.section2_1':`
       syncs with Google Calendar, so everything
       Entries are saved, visible, and editable on all other platforms where you use Google Calendar.
	`,
	'ncalendar.section2_2':`
       This also enables an information processing any third party who has access to Google Calendar.
	`,
}

