export const ncalendar={
	'ncalendar.title':'Nomga Kalenteri',

	'ncalendar.section1':`
     Työajanseurantaa voidaan tehdä monella tavalla. Kalenteri merkintä on yksinkertainen ja helppo ymmärtää.
	`,

	'ncalendar.googletitle':'Google Kalenteri',

	'ncalendar.section2_1':`
       synkronoituu Google Kalenterin kanssa, joten kaikki
       merkinnät ovat tallessa, näkyvillä ja muokattavissa myös kaikilla muilla alustoilla, joilla käytät Google Kalenteria.
	`,
	'ncalendar.section2_2':`
       Tämä mahdollistaa myös tiedon käsittelyn minkä tahansa kolmannen osapuolen toimesta, jolla on yhteys Google Kalenteriin.
	`,
}

