export const timecard={
	'timecard.title':'Työajanseuranta',
	'timecard.timecard':'Kellokortti',
	'timecard.register':'Työaikakirjanpito',

	'timecard.btn_start':'Aloita',
	'timecard.btn_stop':'Lopeta',

	'timecard.section1_1':`
			Mobiililla kellokortilla saat kirjanpidosta täsmällistä ja helposti ylläpidettävää.
	`,
	'timecard.section1_2':`
			Kellokortin käytön yhteydessä voidaan kerätä myös muita oleellisia tietoja ja saada täsmällistä tietoa esim. matka-ajoista, projektien kannattavuudesta, lisätöistä, käytetyistä materiaaleista.
	`,
	
	'timecard.section2':`
		 Hyvin tehdyt merkinnät ovat jo dokumentaatiota itsessään. Lisätään mahdollisuus ottaa kuvia ja täyttää raportteja, niin työ on valmis laskutettavaksi heti.
	`,
	
	'timecard.section3_1':`
			Työaikojen seuranta on Suomessa pakollista myös kuukausipalkkalaisille, toimihenkilöille ja asiantuntijoille.
	`,
	'timecard.section3_2':`
			Koska laki vaatii pitämään työaikakirjanpitoa, niin samalla siitä kannattaa ottaa hyöty irti liiketoiminnassa.
	`,
	'timecard.section3_3':`
			Työaikoja voidaan käyttää palkanmaksun perusteena ja laskutuksen apuna. Niitä voidaan kohdistaa projekteihin ja niihiin voidaan liittää muuta informaatiota. Heti tehty dokumentaatio antaa yritykselle reaaliaikaista tietoa yrityksen tilasta ja auttaa kehittämään tehokkaampia tapoja toimia
	`,
	
	'timecard.section4':`
			Kun työajat kerätään säännönmukaisesti kaikilta on tietojen jatkokäsittely ja analysointi helppoa.
	`,

	'timecard.terminal':'Leimauslaite',

	'timecard.section5_1':`
      Työaikapäätteellä leimaaminen onnistuu nopeasti ja helposti.
	`,

	'timecard.section5_2':`
      Leimauslaite on käytännössä hyvin yksinkertainen. Tietokone, NFC-lukija ja internet yhteys.
	`,

	'timecard.section5_3':`
      Alla esimerkki kokoonpanosta, jossa lisäksi näyttö, jolla voidaan informoida käyttäjää toiminnon
      onnistumisesta.
	`,

	'timecard.section6_1':`
      (esimerkin hinnat alv24% erinäisistä liikkeistä hankittuina)
	`,

	'timecard.section6_2':`
      Lisäksi tarvitaan muistikortti, kotelo, virtalähde ja vähän johtoja
	`,

	'timecard.section7':`
      Valmiita laitteita löytyy markkinoilta, joka tarpeeseen monilla eri ominaisuuksilla varustettuna.
	`,
	'timecard.ul':'Lisäominaisuuksia voivat olla esim:',
  'timecard.li1':'akkuvarmennus',
  'timecard.li2':'mobiiliyhteys',
  'timecard.li3':'releohjaus lukkojen avaamiseen',
  'timecard.li4':'säänkestävyys',
  'timecard.li5':'sormenjälkilukija',
  'timecard.li6':'näppäimistö PIN -koodia varten',
  'timecard.li7':'kamera',

	'timecard.section8_1':`
		Leimauslaite vaatii lisäksi ohjelmiston, jonne kirjauksia lähetetään.
	`,
	'timecard.section8_2':`
    Nomga Kalenteri on yksinkertainen ratkaisu yhden ihmisen tietojen keräämiseen,
    mutta siitä on mahdollista rakentaa koko yrityksen kattava järjestelmä.
	`,
	'timecard.section8_3':`
    Tärkeintä on helpottaa yksittäisen ihmisen menetelmiä laadukkaan tiedon tuottamista varten.
	`,

}


