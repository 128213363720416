import React from 'react';
import { Link } from 'react-router-dom';
import { useIntl } from 'react-intl';

interface IProps {
	to:string,
	children:any,
}


export const LocalizedLink: React.FC<IProps> = ({
	to,
	children,
}) => {
	const { formatMessage, locale } = useIntl();

	const localizeRouteKey = (path: string) => {
    return `/${locale}` + formatMessage({ id: path });
	}

	return (
		<Link to={localizeRouteKey(to)}>{children}</Link>
  );

};
