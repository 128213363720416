import React from 'react';

import CSS from 'csstype';

import { FormattedMessage } from 'react-intl';


const classes=require('./Logo.module.scss');

interface IProps {
	size?:number;
}

const Logo: React.FC<IProps> = (props: IProps) => {
	
	const style:CSS.Properties={}
	const style2:CSS.Properties={}

	return (<div className={classes.logoholder}>
		{/*		<img width="30px" src="/static/images/nomga-n.png" alt="nomga"/>{' '}	*/}
		<img itemProp="logo" className={[classes.img,props.size?classes.big:""].join(" ")} style={style} src="/static/images/nomga-n.png" alt="nomga"/>{' '}

		<div className={[classes.LOGO,props.size?classes.big:""].join(" ")}>
			<div style={style}>NOMGA</div>
			<div style={style2}><FormattedMessage id="logo.software"/></div>
		</div>
	
	</div>);
}

export default Logo;
