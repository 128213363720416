import React from 'react';

// import Page1 from './Page1';
// import Page2 from './Page2';
// import Page3 from './Page3';
// import Page4 from './Page4';
// import Page5 from './Page5';
import ContactPage from './ContactPage';

import NotFound from './NotFound';

import { Route, BrowserRouter as Router } from 'react-router-dom'

import { Container, Row, Col } from 'reactstrap';
import MenuBar from './MenuBar';
import Logo from './Logo';
import NomgaCanvas from './NomgaCanvas';

//import CookieConsent from "react-cookie-consent";

import { Cookie } from './Cookie';

import { LocalizedSwitch, LocalizedRouter, appStrings } from './i18n';
import { AppLanguage, AppRoute } from './i18n/const';

import { FormattedMessage } from 'react-intl';

// @ts-ignore
//import AdSense from 'react-adsense';

import Main from './Main';

interface IProps {
  defaultLanguage?: AppLanguage;
}

const classes=require('./App.module.scss');

const App: React.FC<IProps> = (props: IProps) => {
	return(
	<LocalizedRouter
		RouterComponent={Router}
		languages={AppLanguage}
		appStrings={appStrings}
		defaultLanguage={props.defaultLanguage}
	>
	{/*
	<Container fluid>
		<Row><Col xs={12} md={12} xl={9}>*/}
		<Container className={classes.container} style={{minHeight:"100vh"}}>
			<Row className={classes.headeradd}>
				<Col className="text-center">
					{/*<div className={classes.canvas}>
						<NomgaCanvas/>
					</div>*/}
				{/*
					<div className={classes.biglogo}>
						<Logo size={15} />
					</div>
					*/}
				</Col>
				
			</Row>

			<Row className={classes.menubar}>
				<Col>
					<MenuBar/>
				</Col>
			</Row>

{/*
			<LocalizedSwitch>
				<Route exact path={AppRoute.Home} component={Main} />
				<Route exact path={AppRoute.Warehouse} component={Page1} />
				<Route exact path={AppRoute.Customers} component={Page2} />
				<Route exact path={AppRoute.Invoice} component={Page3} />
				<Route exact path={AppRoute.Worktime} component={Page4} />
				<Route exact path={AppRoute.Timecard} component={Page5} />
				<Route exact path={AppRoute.Contact} component={ContactPage} />


				<Route component={NotFound} />
			</LocalizedSwitch>
*/}
			<ContactPage/>

          <div>
            <NomgaCanvas/>
          </div>

{/*
			<AdSense.Google
	    	style={{display:"block",width:"100%"}}
  	  	client="ca-pub-1614590445455229"
    		slot="9269250395"
     		format="auto"
     		responsive="true"
			/>
*/}

			<Row className={classes.footer}>
				<Col>
					<div className="float-left" itemScope itemType="https://schema.org/Organization">
						<div className={classes.fb}>
							<Logo/>
						</div>
						<div className={classes.fb}>
							<span itemProp="name">Nomga Oy</span><br/>
							Tampere<br/>
							<span itemProp="vatID">2997577-6</span><br/>
						</div>
            {/*
						<div className={classes.fb}>
							<a itemProp="url" href="https://www.nomga.fi">www.nomga.fi</a><br/>
							<a itemProp="email" href="mailto:info@nomga.fi">info@nomga.fi</a><br/>
							<a itemProp="telephone" href="tel:+358504426999">+358 50 442 6999</a><br/>
						</div>
            */}

						{/*
						<div className={classes.fb}>
							<a rel="noopener noreferrer" href="https://nom.nomga.fi"><FormattedMessage id="ncalendar.title"/></a>
						</div>
						*/}
						
						<div className={classes.fb}>
							<a target="_blank" rel="noopener noreferrer" href="https://www.nomga.fi/privacy.html"><FormattedMessage id="privacy"/></a>
						</div>
					</div>

					<img className={classes.bdg} src="/static/images/jasenyritys_banneri_24_150x75_fin_musta.png" alt="Yrittäjät jäsenyritys"/>
					<img className={classes.bdg} src="/static/images/GC-Partner-outline-H.png" alt="Google Cloud Partner"/>
          <a target="_blank" rel="noopener noreferrer" href="https://itewiki.fi/nomga"><img className={classes.bdg} src="/static/images/logo_white.png"/></a>{' '}

				</Col>

			</Row>

	</Container>
	{/*	
	</Col>
	<Col className="d-none d-xl-block">
	<div style={{ minWidth:260,width:260,maxWidth:260 }}>
			<AdSense.Google
	    	style={{display:"block", width:"100%"}}
  	  	client="ca-pub-1614590445455229"
    		slot="4749698686"
     		format="auto"
     		responsive="true"
			/>
	
			<AdSense.Google
	    	style={{display:"block", width:"100%"}}
  	  	client="ca-pub-1614590445455229"
    		slot="8943066893"
     		format="auto"
     		responsive="true"
			/>
			<AdSense.Google
	    	style={{display:"block", width:"100%"}}
  	  	client="ca-pub-1614590445455229"
    		slot="6418003578"
     		format="auto"
     		responsive="true"
			/>
	</div>
	</Col>
	</Row>
	</Container>
	*/}
	
			<Cookie/>

	</LocalizedRouter>


	);
}

export default App;

