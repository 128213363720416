import { Message, SEND_MESSAGE, UPDATE_MESSAGE, DELETE_MESSAGE, DB_INITIALIZE,
	DB_UPDATE,
	ChatActionTypes,
} from './types'

import { initialState } from './reducers'

// TypeScript infers that this function is returning SendMessageAction
export function sendMessage(newMessage: Message): ChatActionTypes {
  return {
    type: SEND_MESSAGE,
    payload: newMessage
  }
}

// TypeScript infers that this function is returning SendMessageAction
export function updateMessage(message: Message): ChatActionTypes {
  return {
    type: UPDATE_MESSAGE,
    payload: message
  }
}


// TypeScript infers that this function is returning DeleteMessageAction
export function deleteMessage(timestamp: number): ChatActionTypes {
  return {
    type: DELETE_MESSAGE,
    meta: {
      timestamp
    }
  }
}


export function dbInitialize():ChatActionTypes {
  let data=localStorage.getItem("db")
	let state=initialState
  try {
    state=JSON.parse(data || "")
  } catch(err) {
  }

  return({
		type:DB_INITIALIZE,
    state,
  })
}


export function dbUpdate(payload: any):ChatActionTypes {
	return {
		type:DB_UPDATE,
    payload: payload,
  }

}
