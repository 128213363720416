export const invoice={
	'invoice.title':'Laskutus',

	'invoice.section1_1':`
      Laskujen teko helpottuu yhdistämällä asiakastiedot, tuntiseuranta ja varastotiedot.
	`,
	'invoice.section1_2':`
      Lisäämällä kellokorttiin kohteen valinta, tarvikkeiden syöttö ym. saadaan lasku esitäytettynä.
      Laskutuksesta vastaavalle henkilölle jää tehtäväksi vain tarkistaa että tiedot ovat oikein ja lähettää
      lasku eteenpäin.
	`,
	'invoice.section1_3':`
      Laskujen lähettäminen eteenpäin riippuu käytössä olevista muista järjestelmistä, jotta saadaan kaikki hyöty irti.
      Voidaan tehdä, joko tulostamalla paperille, sähköpostilla, tiedosto viennillä tai rajapintoja hyödyntäen.
	`,

	'invoice.customer':'Asiakas',
	'invoice.add_product':'Lisää tuote',
	'invoice.add_hours':'Lisää tunnit',

	'invoice.product':'Tuote',
	'invoice.quantity':'Määrä',
	'invoice.price':'Hinta',
	'invoice.total':'Yhteensä',

	'invoice.total_notax':'Veroton summa yhteensä',
	'invoice.total_tax':'Vero (ALV 24%) yhteensä',
	'invoice.total_all':'Laskun summa yhteensä',

}

