export const warehouse={
	'warehouse.title':'Varastonhallinta',
	'warehouse.inventory':'Varastokirjanpito',
	
	'warehouse.section1_1':`
			Tiedä mitä varastossa on.
	`,

	'warehouse.section1_2':`
			Varastonhallinnalla pidät huolen, että varastossa on tavaraa silloin kun sitä tarvitaan. 
	`,

	'warehouse.section2':`
			Varastokirjanpidosta näet, kuka on ottanut mitä ja käyttänyt mihin. Mitään ei jää laskuttamatta.
	`,

	'warehouse.btn_delete_row':'Poista rivi',
	'warehouse.btn_add_row':'Uusi rivi',

}
